/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { Fragment, PureComponent, ReactPortal } from 'react';
import { createPortal } from 'react-dom';

import { ReactElement } from 'SourceType/Common.type';

import { MetaComponentProps } from 'SourceComponent/Meta/Meta.type';
import {MetaComponent as SourceMetaComponent} from 'SourceComponent/Meta/Meta.component';
import BrowserDatabase from "SourceUtil/BrowserDatabase/BrowserDatabase";

/**
 * Page Meta data
 * @class Meta
 * @namespace Component/Meta/Component
 */
export class MetaComponent extends SourceMetaComponent {

    renderTitle(): ReactElement {
        const {
            default_title,
            title_prefix,
            title_suffix,
            title,
        } = this.props;

        const titlePrefix = title_prefix ? `${ title_prefix } | ` : '';
        const titleSuffix = title_suffix ? ` | ${ title_suffix }` : '';

        if (!title || !title?.length) {
            return null;
        }

        return (
            <title>
                { `${ titlePrefix }${ title || default_title }${ titleSuffix }` }
            </title>
        );
    }

    renderCanonical(): ReactElement {
        //Maskinia - hardcoded logo image.
        const logoSrc = "https://shop.maskinia.se/media/logo/stores/1/Maskinia_din_maskinpartner.png";

        const { canonical_url, title } = this.props;

        if (!canonical_url) {
            return null;
        }

        //Maskinia - add og: tags. product data for products.
        const meta = BrowserDatabase.getItem('meta')  || null;
         BrowserDatabase.deleteItem('meta');
        if (meta) {
            // @ts-ignore
            //const logoSrc = meta.image.url;
            const image  = meta.image ||logoSrc ;
            return (<>
                    <link rel="canonical" href={canonical_url}/>
                    <meta property="og:url" content={canonical_url}/>
                    <meta property="og:type" content="website"/>
                    <meta property="og:title" content={title}/>
                    <meta property="og:description" content="Maskinia är generalagent i Sverige för Develon, Case, Bergmann. Vår ambition är att vara din maskinpartner och följa dig i vått och torrt!"/>
                    <meta property="og:image" content={image}/>
                </>
            );
        }

        return (<>
                <link rel="canonical" href={canonical_url}/>
                <meta property="og:url" content={canonical_url}/>
                <meta property="og:type" content="website"/>
                <meta property="og:title" content={title}/>
                <meta property="og:description" content="Maskinia är generalagent i Sverige för Develon, Case, Bergmann. Vår ambition är att vara din maskinpartner och följa dig i vått och torrt!"/>
                <meta property="og:image" content={logoSrc}/>
            </>
        );
    }

    renderMeta(): ReactElement {
        const { metadata } = this.props;

        return [
            <Fragment key="meta-tags">
                { this.renderTitle() }
                { this.renderCanonical() }
                { metadata.map((tag) => {
                    const {
                        name,
                        property = null,
                        content,
                    } = tag;

                    return (
                        <meta
                            key={ name || property }
                            name={ name }
                            content={ content }
                        />
                    );
                }) }
            </Fragment>,
        ];
    }

    render(): ReactPortal {
        return createPortal(
            this.renderMeta(),
            document.head,
        );
    }
}

export default MetaComponent;
